export namespace Image {

    export class ImageSrc {
        constructor(url: string, width: number) {

            this.url = url;
            this.width = width;
        }

        url: string;
        width: number;
    }

    export class SrcSet {
        items: Array<ImageSrc>;

        constructor(imageSet: string) {
            const srcSet = new Array<ImageSrc>();
            const images = imageSet.split(",");
            for (let i = 0; i < images.length; i++) {
                const data = images[i].split(" ");
                const imageUrl = data[0];

                const width = data[1] == null ? "0" : data[1].trim().replace("w", "");
                const set = new ImageSrc(imageUrl, Number.parseInt(width));
                srcSet.push(set);
            }
            srcSet.sort((x, y) => (x.width > y.width)
                ? 1
                : ((x.width < y.width) ? -1 : 0));

            this.items = srcSet;
        }

        getImageUrlFromSrcSet(panelWidth: number): string {

            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].width < panelWidth) {
                    continue;
                } else {
                    return this.items[i].url;
                }

            }

            return this.items[this.items.length - 1].url;
        }
    }
}

export namespace LazyImage {
    export interface ILazyBackgroundImage {
        init(items: JQuery): void;
    }

    export class LazyBackgroundImage implements ILazyBackgroundImage {
        init(items: JQuery): void {
            items.each((index, item) => {
                const element = $(item);
                const imageUrl = element.data("lazy-background-image");
                if (element.is(":visible")) {
                    this.loadImage(imageUrl, element);
                } else {
                    setTimeout(() => this.loadImage(imageUrl, element), 500);
                }
            });
        }

        updateImageData(img: JQuery, element: JQuery, div: JQuery, srcSet: Image.SrcSet, isShopGallery: boolean) {
            let panelWidth = element.innerWidth();

            let imageWidth = 0;
            let imageHeight = 0;
            if (isShopGallery) {
                if (element.innerHeight() * 1.777 > element.innerWidth()) {
                    div.css("height", element.innerHeight() + "px");
                    div.css("width", (element.innerHeight() * 1.777) + "px");
                    panelWidth = element.innerHeight() * 1.777;
                    div.css("left", (element.innerWidth() - (element.innerHeight() * 1.777)) / 2 + "px");
                    imageHeight = element.innerHeight();
                    imageWidth = element.innerHeight() * 1.777;
                } else {
                    div.css("width", element.innerWidth() + "px");
                    div.css("height", (element.innerWidth() / 1.777) + "px");
                    div.css("left", "0");
                    imageHeight = element.innerWidth() / 1.777;
                    imageWidth = element.innerWidth();
                }
            } else {
                div.css("height", "1080px");
                const homeWidth = Math.ceil(panelWidth / 320) * 320;
                div.css("width", homeWidth + "px");
                panelWidth = homeWidth;
                div.css("left", (element.innerWidth() - homeWidth) / 2 + "px");

                imageHeight = 1080;
                imageWidth = homeWidth;
            }

            const imageSrc = srcSet.getImageUrlFromSrcSet(panelWidth);
            img.find("img").attr("src", imageSrc);
            img.find("img").attr("height", imageHeight);
            img.find("img").attr("width", imageWidth);
            img.find("source[type='image/jpeg']").attr("srcset", imageSrc);
            img.find("source[type='image/webp']").attr("srcset", imageSrc.replace(".jpg", ".webp"));
        }


        loadImage(imageSet: string, element: JQuery) {
            const alt = element.data("lazy-background-alt");

            const isShopGallery = element.parents(".shop-gallery").length === 1;
            const img = $(`<picture class="background-image">   
                          <source type="image/webp">
                          <source type="image/jpeg">
                          <img   />
                          </picture>`);
            img.find("img").attr("alt", alt);
            const srcSet = new Image.SrcSet(imageSet);
            const div = $(`<div class="background-image" />`);
            const overlay = $(`<div class="overlay" />`);
            this.updateImageData(img, element, div, srcSet, isShopGallery);

            div.append(img);
            div.append(overlay);
            element.append(div);


            $(window).on("resize",
                () => {
                    this.updateImageData(img, element, div, srcSet, isShopGallery);
                });
            img.on("load",
                () => {
                    img.css("display", "block");
                });
        }
    }

    export interface IImageLoader {
        initLoadImage()
    }

    export class ImageLoader implements IImageLoader {
        loadImage(item: any) {
            const src = $(item).data("src");
            const alt = $(item).data("alt");
            const cssClass = $(item).data("class");

            const width = $(item).data("width");
            const height = $(item).data("height");
            const sizes = $(item).data("sizes");

            const srcSet: Image.SrcSet = new Image.SrcSet(src);
            // without srcset
            if (srcSet.items.length == 1 && srcSet.items[0].width === 0) {
                const img = $("<img>");
                img.attr("src", src);
                img.attr("alt", alt);
                img.attr("class", cssClass);
                if (width > 0 && height > 0) {
                    img.attr("width", width);
                    img.attr("height", height);
                }
                img.on("load",
                    () => {
                        const imagePreview = $(item).find(".image-preview");
                        imagePreview.append(img);
                        $(item).find(".loader-dual-ring").fadeTo("slow", 0.0);
                        $(item).find(".image-place-holder").fadeTo("slow", 0.0);
                        imagePreview.fadeTo("slow",
                            1.0,
                            () => {
                                $(item).replaceWith(img);
                            });
                    });
            } else {
                const img = $(`<picture class="background-image">   
                          <source type="image/webp">
                          <source type="image/jpeg">
                          <img   />
                          </picture>`);
                img.hide();
                img.insertBefore($(item));
                img.find("img").attr("alt", alt);
                img.find("img").attr("class", cssClass);

                if (width > 0) {
                    img.find("source").attr("sizes", `${width}px`);
                }

                img.find("img").attr("src", srcSet.items[0].url);
                img.find("source[type='image/jpeg']").attr("srcset", src);
                img.find("source[type='image/jpeg']").attr("sizes", sizes);
                const searchRegExp = new RegExp("\\.jpg", 'g');
                img.find("source[type='image/webp']").attr("srcset", src.replace(searchRegExp, ".webp"));
                img.find("source[type='image/webp']").attr("sizes", sizes);

                img.find("img").on("load",
                    () => {
                        const imagePreview = $(item).find(".image-preview");
                        $(item).find(".loader-dual-ring").fadeTo("slow", 0.0);
                        $(item).find(".image-place-holder").fadeTo("slow", 0.0);
                        imagePreview.fadeTo("slow",
                            1.0,
                            () => {
                                $(item).remove();
                                img.show();
                            });
                    });
            }
        }

        initLoadImage() {
            // If all IntersectionObserver and IntersectionObserverEntry
            // features are natively supported. Use It
            const anyWindow: any = window;
            if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in anyWindow.IntersectionObserverEntry.prototype) {
                // Minimal polyfill for Edge 15's lack of `isIntersecting`
                // See: https://github.com/w3c/IntersectionObserver/issues/211
                if (!("isIntersecting" in anyWindow.IntersectionObserverEntry.prototype)) {
                    Object.defineProperty(anyWindow.IntersectionObserverEntry.prototype,
                        "isIntersecting",
                        {
                            get() {
                                return this.intersectionRatio > 0;
                            }
                        });
                }

                const options = {
                    rootMargin: "0px",
                    threshold: 0.0
                };

                const callback = (entries: any, observer: any) => {
                    entries.forEach((entry: any) => {
                        if (entry.isIntersecting) {
                            const item = entry.target;
                            this.loadImage(item);
                            observer.unobserve(item);
                        }
                    });
                };

                const intersectionObserver = new IntersectionObserver(callback, options);
                $('[data-load-image="true"]').each((index, item) => {
                    if ($(item).data("done") === "true") {
                        return;
                    }
                    $(item).data("done", "true");
                    intersectionObserver.observe(item);
                });
            } else {
                $('[data-load-image="true"]').each((index, item) => {
                    if ($(item).data("done") === "true") {
                        return;
                    }
                    $(item).data("done", "true");
                    this.loadImage(item);
                });
            }
        }
    }
}



jQuery(function () {
   

    const imageLoader: LazyImage.IImageLoader = new LazyImage.ImageLoader();
    imageLoader.initLoadImage();

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationsList: any) => {
        for (const mutation of mutationsList) {
            if (mutation.type === "childList") {
                imageLoader.initLoadImage();
            }
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    $("[dynamic=\"true\"]").each(function () {
        observer.observe(this, config);
    });
});
