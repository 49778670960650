export namespace Svg {

    export interface IImageLoader {
        init(selector: string): void;
    }

    export class ImageLoader implements IImageLoader {
        init(selector: string): void {
            const container = $(`<div style="display: none;"></div>`);
            $("body").append(container);

            const url = $(selector).data("href");

            if (!url) {
                return;
            }

            const hashIndex = url.indexOf('#');
            const spriteUrl = url.substr(0, hashIndex);

            $.ajax({
                url: spriteUrl,
                type: 'GET',
                dataType: "text",
            }).done((data: string) => {
                container.html(data);
            });
        }
    }
}
