export namespace ShoppingCart {

    export class CartCache {
        id: string;
    }

    export class Cart {
        id: string;
        currency: string;
        currencyCode: string;
        totalNet: string;
        totalVat: string;
        totalGross: string;
        shipmentModel: ShipmentModel;
        items: CartItem[] = [];
        molSessions: MolSession[] = [];
        totalItems: CartTotalItem[] = [];
    }

    export class ShipmentModel {
        shipmentPriceInformation: string;
        shipmentSpecialPriceInformation: string;
    }

    export class MolSession {
        cartId: string;
        localeId: string;
        openProjectUrl: string;
        productDescription: string;
        productName: string;
        projectId: string;
    }

    export class CartItem {
        cartId: string;
        productId: string;
        productName: string;
        productDescription: string;
        productImage: string;
        quantity: number;
        displayPrice: string;
        displayVatRate: string;
        displaySubtotal: string;
        categoryUrl: string;
        price: number;
    }

    export class CartTotalItem {
        vatRate: string;
        net: string;
        vat: string;
        gross: string;
    }

    class Storage {

        cartCache: CartCache;
        cart: Cart;
        labelQty: JQuery<HTMLElement>;
        cartItemUrl = "/cart/cart-item";
        maxTotal = 50;
        ready: PromiseLike<boolean>;
        enabledShop = false;

        constructor() {
            this.labelQty = $("#cart-label-count");
            this.attachEventToLogout();

            this.ready = this.sendRequest("GET", "/cart/check", null).then(async x => {
                this.enabledShop = x;
                if (this.enabledShop) {
                    await this.getCart();
                }
            });
        }

        private attachEventToLogout() {
            $("#logoutForm").on("submit", (e) => {
                localStorage.removeItem("cart");
            });
        }

        private async getCart() {
            this.cart = new Cart();
            this.cartCache = new CartCache();

            const cart = localStorage.getItem("cart");
            if (cart === null) {
                await this.addCartRequest();
            } else {
                this.cartCache = JSON.parse(cart);
                await this.getCartRequest();
            }
        }

        async addToCart(cartItem: CartItem) {
            cartItem.cartId = this.cartCache.id;
            analyticsAddToCartEvent(cartItem);
            await this.addCartItemRequest(cartItem);
        }

        async updateCart(cartItem: CartItem) {
            cartItem.cartId = this.cartCache.id;
            await this.updateCartItemRequest(cartItem);
        }

        async deleteFromCart(cartItem: CartItem) {
            cartItem.cartId = this.cartCache.id;
            analyticsRemoveFromCartEvent(cartItem);
            await this.deleteCartItemRequest(cartItem);
        }

        private async addCartRequest() {
            const data = await this.sendRequest("POST", "/cart/add-cart", null);
            this.cartCache.id = data.id;
            localStorage.setItem("cart", JSON.stringify(this.cartCache));

            await this.updateCartModel(data, true);
        }

        private async getCartRequest() {
            const data = await this.sendRequest("GET", `/cart/data-${this.cartCache.id}`, null);

            await this.updateCartModel(data, false);
        }

        private async updateCartModel(data: Cart, isNew: boolean) {

            this.setLinkToCart();

            this.cart = new Cart();

            if (data === undefined && !isNew) {
                await this.addCartRequest();
                this.cart.id = this.cartCache.id;
            } else if (data.currency === null) {
                return;
            } else {
                this.cart = data;
            }

            const sum = this.cart.items.reduce((p, c) => {
                return p + c.quantity
            }, 0);

            if (sum > 0) {
                this.labelQty.text(sum);
                this.labelQty.removeClass("hide-badge");
            }
            else {
                this.labelQty.addClass("hide-badge");
            }
        }

        private setLinkToCart() {
            $("[data-cart-dropdown-view]").attr("href", `${window.location.origin}/cart/${this.cartCache.id}`);

            const markOnlineMenuLink = $('a.nav-category-link[href*="markonline"]');
            if (markOnlineMenuLink.length === 0) {
                return;
            }
            const oldMarkOnlineMenuLink = markOnlineMenuLink.attr("href");
            const cidIdx = oldMarkOnlineMenuLink.indexOf("cid");
            let newMarkOnlineMenuLink = "";
            if (cidIdx > 0) {
                newMarkOnlineMenuLink = oldMarkOnlineMenuLink.substring(0, cidIdx);
            } else {
                const slashIdx = oldMarkOnlineMenuLink.lastIndexOf("/");
                newMarkOnlineMenuLink = `${oldMarkOnlineMenuLink.substring(0, slashIdx)}/`;
            }
            markOnlineMenuLink.attr("href", `${newMarkOnlineMenuLink}cart,${this.cartCache.id}`);

            const markOnlineCategoryLink = $('a#category-markonline-link[href*="markonline"]');
            if (markOnlineCategoryLink.length === 0) {
                return;
            }
            markOnlineCategoryLink.attr("href", markOnlineCategoryLink.attr("href").replace('cartId', this.cartCache.id));
        }

        private async addCartItemRequest(cartItem: CartItem) {
            await this.sendRequest("POST", this.cartItemUrl, JSON.stringify(cartItem));
            this.getCartRequest();
        }

        private async updateCartItemRequest(cartItem: CartItem) {
            await this.sendRequest("PUT", this.cartItemUrl, JSON.stringify(cartItem));
            await this.getCartRequest();
        }

        private async deleteCartItemRequest(cartItem: CartItem) {
            await this.sendRequest("DELETE", this.cartItemUrl, JSON.stringify(cartItem));
            await this.getCartRequest();
        }

        private sendRequest(type: string, url: string, data: string): any {
            return $.ajax({
                contentType: 'application/json',
                data,
                dataType: 'json',
                processData: false,
                type,
                url
            });
        }
    }

    export class QtySelector {

        inputQty: JQuery<HTMLElement>;
        errorQtyObject: JQuery<HTMLElement>;

        alwaysAvailable: boolean;
        availability: number;

        minQty = 1;
        maxQty = 999;

        currentValue: number;

        attachEvents(inputSelector: string, minusSelector: string, plusSelector: string, errorSelector: string, triggerChange: boolean) {

            this.inputQty = $(inputSelector);
            this.errorQtyObject = $(errorSelector);

            if (this.inputQty.length === 0) {
                return;
            }

            this.currentValue = parseInt(this.inputQty.val().toString());

            $(minusSelector).on('click', (e) => {
                e.preventDefault();
                let value = parseInt(this.inputQty.val().toString());

                if (value > this.minQty + 1) {
                    value = value - 1;
                } else {
                    value = this.minQty;
                }

                this.setInputValue(value, triggerChange);
            });

            $(plusSelector).on('click', (e) => {
                e.preventDefault();
                let value = parseInt(this.inputQty.val().toString());

                if (value < this.maxQty) {
                    value = value + 1;
                } else {
                    value = this.maxQty;
                }

                this.setInputValue(value, triggerChange);
            });

            this.inputQty.on("input keydown keyup contextmenu drop", () => {
                let properValue = this.inputQty.val().toString().replace(/[^\d]+/g, '');
                if (properValue.length === 0) {
                    properValue = "1";
                }
                if (Number(properValue) >= this.maxQty) {
                    properValue = this.maxQty.toString();
                }

                this.setInputValue(Number(properValue), triggerChange);
            });
        }

        setInputValue(value: number, triggerChange: boolean) {
            if (triggerChange && this.currentValue !== value) {
                this.inputQty.val(value).trigger("change");
            } else {
                this.inputQty.val(value);
            }
            this.currentValue = value;
            this.setAva();
        }

        setAva() {
            const ava = this.inputQty.attr("data-cart-prod-ava");
            this.alwaysAvailable = ava === "always";
            this.availability = this.alwaysAvailable ? 0 : Number(ava);

            if (this.currentValue > this.availability && !this.alwaysAvailable) {
                this.errorQtyObject.css("display", "block");
            } else {
                this.errorQtyObject.css("display", "none");
            }
        }
    }

    export const storage = new Storage();

    export class Modal {

        modalSelector = "#cartModal";
        modalObject: JQuery<HTMLElement>;
        errorMaxObject: JQuery<HTMLElement>;
        qtySelector = new QtySelector();

        init() {
            this.modalObject = $(this.modalSelector);
            this.errorMaxObject = this.modalObject.find(".cartModal-max-error");

            if ($(`${this.modalSelector}[done!=true]`).length > 0) {
                this.qtySelector.attachEvents(".cartModal-qty", ".cartModal-minus-btn", ".cartModal-plus-btn", ".cartModal-qty-error", false);
                this.attachEvents();
                this.reset();
                this.modalObject.attr("done", "true");
            }
        }

        attachEvents() {
            const cancelBtn = $("[data-cart-modal-cancel-btn]");
            const addBtn = $("[data-cart-modal-add-btn]");
            const addGoBtn = $("[data-cart-modal-add-go-btn]");

            this.modalObject.find(".custom-modal").on('click', (e) => {
                if (e.target === e.currentTarget) {
                    this.reset();
                }
            })
            cancelBtn.on('click', () => {
                this.reset();
            });
            addBtn.on('click', async () => {
                if (!this.checkIfCanAdd()) {
                    return;
                }

                await this.addToCart();

                this.reset();

                this.modalObject.css("display", "none");
                this.modalObject.modal('hide');
            });
            addGoBtn.on('click', async () => {
                if (!this.checkIfCanAdd()) {
                    return;
                }

                await this.addToCart();
                if (storage.cartCache && storage.cartCache.id) {
                    window.location.href = `${window.location.origin}/cart/${storage.cartCache.id}`;
                }
            });
        }

        checkIfCanAdd(): boolean {
            if (storage.cart.items.length >= storage.maxTotal) {
                this.errorMaxObject.css("display", "block");
                return false;
            } else {
                this.errorMaxObject.css("display", "none");
                return true;
            }
        }

        reset() {
            this.errorMaxObject.css("display", "none");
            this.qtySelector.inputQty.val(1);
        }

        async addToCart() {
            const cartItem = new CartItem();
            cartItem.productId = this.modalObject.find(".cartModal-body").attr("data-cart-modal-product-id");
            cartItem.productName = this.modalObject.find(".cartModal-title").text();
            cartItem.quantity = Number(this.qtySelector.inputQty.val());

            const priceElements = this.modalObject.find("[name='ga-price']");
            cartItem.price = priceElements.length === 1 ? convertPrice(priceElements[0].innerText) : 0;

            await storage.addToCart(cartItem);
        }
    }

    export class Dropdown {

        itemsContainer: JQuery<HTMLElement>;
        molItemsContainer: JQuery<HTMLElement>;
        molItemsPanel: JQuery<HTMLElement>;

        emptyDiv: string;
        molEmptyDiv: string;

        init() {
            const emptyText = $("[data-empty-cart-label]").data("empty-cart-label");
            this.emptyDiv = `<div class="cart-dropdown-empty">${emptyText}</div>`;

            this.itemsContainer = $("[data-cart-dropdown-items]");
            this.molItemsContainer = $("[data-mol-sessions-items]");
            this.molItemsPanel = $("[data-mol-sessions-items], [data-mol-sessions-items]+, [data-mol-sessions-header]");
            this.attachEvents();
        }

        attachEvents() {
            $("#cart-dropdown-menu").on("show.bs.dropdown", () => {
                const pathname = window.location.pathname;
                if (pathname.includes("cart") && !pathname.includes("cart,")) {
                    return false;
                }
                if (pathname.includes("checkout")) {
                    location.href = `/cart/${storage.cart.id}`;
                    return false;
                }

                if (storage.cart.items.length === 0) {
                    this.itemsContainer.html(this.emptyDiv);
                } else {
                    const itemsContainer = $("li[data-cart-dropdown-items]");
                    itemsContainer.empty();
                    storage.cart.items.forEach((item) => {
                        itemsContainer.append(this.renderItem(item));
                    });
                }

                if (storage.cart.molSessions.length === 0) {
                    this.molItemsPanel.hide();
                } else {
                    this.molItemsPanel.show();
                    this.molItemsContainer.empty();
                    storage.cart.molSessions.forEach((item) => {
                        this.molItemsContainer.append(this.renderMolItem(item));
                    });
                }

                this.attachCartRemoveBtnEvent();
                return true;
            });

            $(".cart-dropdown-items").on("click", (e) => {
                e.stopPropagation();
            });
        }

        attachCartRemoveBtnEvent() {
            document.querySelectorAll(".cart-remove-btn").forEach(btn => btn.addEventListener("click", async (event) => {
                event.preventDefault();

                const cartItem = new CartItem();

                const item = (event.currentTarget as any).parentElement.parentElement;
                cartItem.productId = item.getAttribute("data-cart-remove-item-id");

                const itemInfo = item.querySelector(".item-info");
                const info = itemInfo?.children;
                if (info?.length === 3) {
                    cartItem.quantity = Number(info[0].innerText);
                    cartItem.productName = info[1].innerText;
                    cartItem.price = convertPrice(info[2].innerText);
                }

                await storage.deleteFromCart(cartItem);
                item.remove();

                if (this.itemsContainer.children().length === 0 || storage.cart.items.length === 0) {
                    this.itemsContainer.html(this.emptyDiv);
                }
            }));
        }

        renderItem(item: CartItem) {
            return `<span class="item" data-cart-remove-item-id="${item.productId}">
                        <span class="item-left">
                            <a href="${item.categoryUrl}">
                                <span class="item-info">
                                    <span>${item.quantity}</span> x <span>${item.productName}</span>
                                    <span class="float-right">${item.displaySubtotal}</span>
                                </span>
                            </a>
                        </span>
                        <span class="item-right"><button class="btn-xs btn-danger pull-right cart-remove-btn">x</button></span>
                    </span>`;
        }

        renderMolItem(item: MolSession) {
            return `<span class="item">
                        <span class="item-left">
                            <a href="${item.openProjectUrl}">
                                <span class="item-info">
                                    <span>${item.productName}</span>
                                </span>
                            </a>
                        </span>
                    </span>`;
        }
    }

    export class Page {

        table: JQuery<HTMLElement>;
        tableRows: JQuery<HTMLElement>;
        shareModal: HTMLElement;
        successMessage: HTMLElement;
        shareLinkInput: HTMLInputElement;
        copyToClipboardButton: HTMLButtonElement;
        clearMessageTimeout: NodeJS.Timeout;

        init() {
            this.table = $(".table#cart");
            this.tableRows = $(".table#cart>tbody");
            this.shareModal = document.getElementById("shareMolProjectModal");
            if (this.shareModal) {
                this.shareLinkInput = this.shareModal.querySelector("#shareLink") as HTMLInputElement;
                this.copyToClipboardButton = this.shareModal.querySelector("#copyToClipboard") as HTMLButtonElement;
                this.successMessage = this.shareModal.querySelector("#successMessage") as HTMLButtonElement;
            }

            this.attachQtySelectors();
            this.attachEvents();
        }

        attachEvents() {
            $(".cart-go-back").on("click", () => {
                document.location.href = this.setGoBackLink();
            });

            if (this.copyToClipboardButton) {
                this.copyToClipboardButton.addEventListener("click", async (event) => {
                    navigator.clipboard.writeText(this.shareLinkInput.value)
                        .then(
                            () => {
                                this.successMessage.classList.remove("d-none");
                                if (this.clearMessageTimeout) {
                                    clearTimeout(this.clearMessageTimeout);
                                }
                                this.clearMessageTimeout = setTimeout(() => this.successMessage.classList.add("d-none"), 1000)
                            }
                        );
                });
            }

            document.querySelectorAll("button.open-share-project-dialog").forEach(btn => btn.addEventListener("click", async (event) => {
                var button = btn as HTMLButtonElement;
                var shareUrl = button.dataset["shareUrl"];
                this.shareLinkInput.value = shareUrl;
            }));

            document.querySelectorAll(".cart-page-remove-btn").forEach(btn => btn.addEventListener("click", async (event) => {
                const cartItem = new CartItem();

                const item = (event.currentTarget as any).parentElement.parentElement;
                cartItem.productId = item.getAttribute("data-cart-remove-item-id");

                cartItem.quantity = Number(item.querySelector("input[name^='cart-page-qty'")?.value ?? 0);
                cartItem.productName = item.querySelector("[name='ga-name']")?.innerText;
                const price = item.querySelector("[data-subtotal-value]");
                cartItem.price = price ? convertPrice(price.innerText) : 0;

                await storage.deleteFromCart(cartItem);

                if (item.nextElementSibling?.classList.contains("mol-row")) {
                    item.nextElementSibling.remove();
                }
                item.remove();

                this.updateTotal();

                if (this.tableRows.children().length === 0 || storage.cart.items.length === 0) {
                    const emptyLabel = $("[data-empty-cart-label]").data("empty-cart-label");
                    const emptyBtn = $("[data-empty-cart-btn]").data("empty-cart-btn");
                    (this.table as any).loadTemplate($("#shopping-cart-empty"), { cartEmpty: emptyLabel, return: emptyBtn, returnLink: this.setGoBackLink() });
                    $("[data-total-summary-section]").remove();
                }
            }));
        }

        private setGoBackLink() {
            const lastPage = document.referrer;
            const skipHttps = 8;
            const refererHostEndIndex = document.referrer.indexOf('/', skipHttps);
            const refererHost = document.referrer.substring(skipHttps, refererHostEndIndex);
            const locationHost = document.location.href.substring(skipHttps, refererHostEndIndex);
            if (refererHost !== locationHost) {
                return `/shop/markonline/cart,${ShoppingCart.storage.cartCache.id}`;
            } else {
                return lastPage.includes("checkout") ? "/search" : lastPage;
            }
        }

        attachQtySelectors() {
            const inputs = $("input[name^='cart-page-qty'");

            inputs.each((index, inputElement) => {
                const inputTarget = $(inputElement);

                const minus = inputTarget.prev().attr("name");
                const input = inputTarget.attr("name");
                const plus = inputTarget.next().attr("name");
                const error = inputTarget.parent().prev().attr("name");

                const qtySelector = new QtySelector();
                qtySelector.attachEvents(`[name=${input}]`, `[name=${minus}]`, `[name=${plus}]`, `[name=${error}]`, true);

                let debounce = null;
                $(`[name=${input}]`).on("change", () => {
                    clearTimeout(debounce);
                    debounce = setTimeout(() => {
                        this.saveQtyChange($(`[name=${input}]`));
                    }, 1000);
                });
            });
        }

        async saveQtyChange(target: JQuery<EventTarget>) {

            if (target.data('requestRunning')) {
                return;
            }
            target.data('requestRunning', true);

            const cartItem = new CartItem();
            cartItem.productId = target.data("product-id");
            cartItem.quantity = Number(target.val());

            await storage.updateCart(cartItem);

            this.updateSubtotal(cartItem);

            target.data('requestRunning', false);
        }

        updateSubtotal(cartItem: CartItem) {
            const subtotal = $(`[data-cart-remove-item-id="${cartItem.productId}"]>[data-subtotal-value]`);
            const cartItemDb = storage.cart.items.find(x => x.productId === cartItem.productId);
            subtotal.text(cartItemDb.displaySubtotal);

            this.updateTotal();
        }

        updateTotal() {
            const totalNet = $("[data-total-net-value]");
            const totalVat = $("[data-total-vat-value]");
            const total = $(`[data-total-value]`);

            totalNet.text(storage.cart.totalNet);
            totalVat.text(storage.cart.totalVat);
            total.text(storage.cart.totalGross);

            this.updateShipment();
        }

        updateShipment() {
            var shipmentLine1 = $("[data-shipment-price]");
            var shipmentLine2 = $("[data-special-price]");

            shipmentLine1.text(storage.cart.shipmentModel.shipmentPriceInformation);
            shipmentLine2.text(storage.cart.shipmentModel.shipmentSpecialPriceInformation);
        }
    }

    export class SuggestButton {
        loaderTemplate = `<div class="loader-container"><div class="loader mx-auto"></div></div>`;
        selector = "[data-add-suggested-product]";
        modalSelector = "#cartModal";
        button: JQuery<HTMLElement>;
        cartModal: JQuery<HTMLElement>;

        init() {
            this.button = $(this.selector);
            this.cartModal = $(this.modalSelector);

            var specificationPanel = this.cartModal.find(".specification-panel");

            this.button.on("click", (event) => {
                specificationPanel.hide();

                var target = event.target;
                var productId = $(target).data("add-suggested-product");
                this.cartModal.find(".cartModal-body").attr("data-cart-modal-product-id", productId);

                var productTile = $(target).parents(".suggested-product-tile");

                var pricePanel = productTile.find(".price-label");
                this.cartModal.find(".cartModal-price-title > .price-label").html(pricePanel.html());

                var itemCodePanel = productTile.find(".item-code");
                this.cartModal.find(".cartModal-title").html(itemCodePanel.html());

                var image = productTile.find("img").parent();
                this.cartModal.find("[data-image=true]").html(image[0].outerHTML);

                var description = productTile.find("[data-description=true]");
                this.cartModal.find("[data-product-name=true]").html(description.html());

                var propertiesPanel = this.cartModal.find(".properties-panel");
                propertiesPanel.html(this.loaderTemplate);

                $.ajax({
                    url: "/api/search/SearchProduct?productId=" + productId,
                    type: 'GET',
                    contentType: false, // tell jQuery not to set contentType
                }).done((data) => {
                    const specification = data.products[0].specification;
                    this.cartModal.find("[data-product-spec=true]").html(specification);
                    if (specification) {
                        specificationPanel.css("display", "block");
                    }
                    const properties = data.products[0].properties;
                    const fistColumnLength = Math.ceil(properties.length / 2);
                    const propertiesColumn1 = properties.slice(0, fistColumnLength);
                    const propertiesColumn2 = properties.slice(fistColumnLength);

                    (propertiesPanel as any).loadTemplate($("#properties-panel-template"),
                        {
                            column1: propertiesColumn1,
                            column2: propertiesColumn2,
                        }
                    );
                });


                this.cartModal.modal('show');
            });
        }
    }

    export function convertPrice(priceText: string): number {
        return Number(priceText.replace(storage.cart.currency, '').replace(',', '.').trim());
    }

    export function analyticsAddToCartEvent(cartItem: CartItem) {
        analyticsCartEvent("add_to_cart", cartItem, cartItem.price, cartItem.price * cartItem.quantity);
    }

    export function analyticsRemoveFromCartEvent(cartItem: CartItem) {
        analyticsCartEvent("remove_from_cart", cartItem, Math.round((cartItem.price / cartItem.quantity) * 100) / 100, cartItem.price);
    }

    export function analyticsCartEvent(event: string, cartItem: CartItem, price: number, total: number) {
        if (!cartItem.cartId) {
            return;
        }

        var model = {
            "event": event,
            "ecommerce": {
                "value": total,
                "currency": storage.cart.currencyCode,
                "items": [
                    {
                        "item_id": cartItem.productName,
                        "item_name": cartItem.productName,
                        "price": price,
                        "quantity": cartItem.quantity
                    }]
            }
        };

        if ((window as any).dataLayer === undefined) {
            return;
        }

        (window as any).dataLayer.push({ ecommerce: null });
        (window as any).dataLayer.push(model);
    }
}

jQuery(function () {
    ShoppingCart.storage.ready.then(() => {
        if (!ShoppingCart.storage.enabledShop) {
            return;
        }

        const shoppingCartDropdown = new ShoppingCart.Dropdown();
        shoppingCartDropdown.init();
        const shoppingCartPage = new ShoppingCart.Page();
        shoppingCartPage.init();
        const modal = new ShoppingCart.Modal();
        modal.init();

        const suggestButton = new ShoppingCart.SuggestButton();
        suggestButton.init();

        $("#cart-dropdown-menu").css('visibility', 'visible');

        var loginDropDownMenu = document.getElementById("login-dropdown-menu");
        loginDropDownMenu.dataset["cartId"] = ShoppingCart.storage.cartCache.id;

        $("#login-dropdown-menu").css('visibility', 'visible');

        if ($("[data-go-markonline]").length > 0) {
            if (document.location.href.endsWith('/')) {
                document.location.href = `/shop/markonline/cart,${ShoppingCart.storage.cartCache.id}`;
            } else {
                const templateId = new URLSearchParams(document.location.search).get("templateId");
                if (templateId && templateId.length === 24) {
                    document.location.pathname = `/shop/markonline/cart,${ShoppingCart.storage.cartCache.id}`;
                } else {
                    const currentLocation = document.location.href
                        .replace(`?templateId=${templateId}&`, "?")
                        .replace(`?templateId=${templateId}`, "")
                        .replace(`&templateId=${templateId}`, "")
                        .replace(`/shop/markonline`, `/shop/markonline/cart,${ShoppingCart.storage.cartCache.id}`);
                    document.location.href = currentLocation;
                }
            }
        }
    });
});
